<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
        <b-col>
        <b-form-group label-for="search" label="search" label-cols-lg="2" content-cols-lg="8" label-align-lg="right">
          <b-form-input v-model="filter" id="search" placeholder="type to search" />
        </b-form-group>
        <b-table style="font-size: 12px" striped hover :items="dataFiltered" :fields="fields" :filter="filter" @row-clicked="showScenario"></b-table>
      </b-col>
    </b-row>
    <b-row v-if="show.scenario">
      <b-col>
        <b-row class="mb-3">
          <b-col>
            <h1 class="text-dark kstyle mb-4"> Obligation {{ scenario.ref }}</h1>
            <b-table stacked :items="[scenario]" :fields="config"></b-table>
            <div v-for="item in scenario" :key="item.ref">
              {{item}}
            </div>
          </b-col>
        </b-row>
         <b-row class="mb-5">
          <b-col><b-btn @click="showTable">show all</b-btn></b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ObligationsAppStart",
  computed: {
    dataFiltered: {
      get () {
        return this.$store.state.appObligationsData
      }
    },
    fields: {
      get () {
        return this.$store.state.appObligationFields
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Obligations app main', action: 'open Obligations app main page' })
  },
  data () {
    const data = {
      filter: '',
      scenario: {},
      show: {
        scenario: false,
        table: true
      }
    }
    return data
  },
  methods: {
    showScenario: function (scenario) {
      this.scenario = scenario
      this.clearShow()
      this.show.scenario = true
    },
    showTable: function () {
      this.clearShow()
      this.show.table = true
    }
  }
}
</script>

<style>
</style>
